<template>
  <v-card>
    <v-card-title class="pb-4"
      ><span class="headline">{{ title }}</span></v-card-title
    >
    <v-card-text class="pt-4">
      <v-data-table
        id="detallesTable"
        :headers="detallesHeader"
        :header-props="{ sortIcon: null }"
        class="elevation-1"
        :items="detallesItems"
        :loading="isLoading"
        dense
        fixed-header
        hide-default-footer
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        :items-per-page="itemPerPage"
      >
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-row justify="end" class="mr-4">
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "DetalleArchivoAfip",
  components: {
    PageHeader,
  },
  props: ["archivoId"],
  data: () => ({
    title: enums.titles.DETALLE_ARCHIVO_AFIP,
    detallesItems: [],
    isLoading: false,
    itemsPerPageOptions: [5, 10, 30, 40, 50],
    itemPerPage: 5,
    detallesHeader: [
      {
        text: "Acta n°",
        align: "start",
        value: "actasFiscalizacionId",
      },
      { text: "Empresa", value: "acta.empresa" },
      { text: "Estado", value: "acta.estado" },
      { text: "Importe", value: "acta.importe" },
    ],
    archivo: {},
  }),
  created() {},
  mounted() {
    this.cargarTabla();
  },

  methods: {
    ...mapActions({
      getArchivoFiscalizacionById: "aportes/getArchivoFiscalizacionById",
      getActaFiscalizacionById: "aportes/getActaFiscalizacionById",
      getArchivoFiscalizacionDetalleById: "aportes/getArchivoFiscalizacionDetalleById",
      setAlert: "user/setAlert",
    }),
    closeModal() {
      this.modalOpen = false;
      this.$emit("close", false);
      this.$refs.form.reset();
    },

    async cargarTabla() {
      this.isLoading = true;
      try {
        const response = await this.getArchivoFiscalizacionDetalleById(
          this.archivoId
        );
        this.detallesItems = response;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
      
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
  },
};
</script>
