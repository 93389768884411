<template>
  <v-container>
     <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
    <PageHeader :title="title" class="pt-5 " />
    <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
      <v-container class="pt-0">
        <v-row justify="end">
          <v-col cols="1" align-self="center" align="center" class="pr-0 pl-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="showFilters = !showFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-show="!showFilters">
          <v-col cols="12" align="left" class="pt-0 pb-0">
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in">
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <!-- Filtros comunes -->
            <v-row>
              <!-- Fecha desde -->
              <v-col cols="12" sm="6" md="4" class="pb-0">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaDesdeSelected"
                      label="Fecha desde"
                      :append-icon="calendarIcon"
                      v-bind="attrs"
                      outlined
                      dense
                      hint="Formato DD/MM/AAAA"
                      @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                      v-on="on"
                      :rules="[
                        (!!fechaDesdeSelected &&
                          !!fechaHastaSelected &&
                          new Date(parseDateToIso(fechaDesdeSelected)) <=
                            new Date(parseDateToIso(fechaHastaSelected))) ||
                          'Formato incorrecto'
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaDesde"
                    no-title
                    @change="fechaDesdeSelected = formatDate(fechaDesde)"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- Fecha hasta -->
              <v-col cols="12" sm="6" md="4" class="pb-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaHastaSelected"
                      label="Fecha hasta"
                      :append-icon="calendarIcon"
                      hint="Formato DD/MM/AAAA"
                      @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="[
                        (!!fechaDesdeSelected &&
                          !!fechaHastaSelected &&
                          new Date(parseDateToIso(fechaDesdeSelected)) <=
                            new Date(parseDateToIso(fechaHastaSelected))) ||
                          'Formato incorrecto'
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaHasta"
                    no-title
                    @change="fechaHastaSelected = formatDate(fechaHasta)"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pb-0">
                <v-text-field
                  ref="periodoD"
                  v-model="periodo"
                  label="Período informado"
                  outlined
                  dense
                  clearable
                  autocomplete="not"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  :rules="rules.periodoYyyyMm"
                  hint="Formato AAAAMM"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                :disabled="!isFormValid"
                elevation="2"
                small
                type="submit"
                form="filters-form"
              >
                Aplicar
              </v-btn>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        id="actasTable"
        :headers="procesosHeader"
        :header-props="{ sortIcon: null }"
        class="elevation-1"
        :items="archivosFiscalizacion"
        :loading="isLoading"
        :search="search"
        :single-select="singleSelect"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :items-per-page="itemPerPage"
      >
        <template v-slot:[`item.importe`]="{ item }">
          <span>{{ addDecimals(item.importe) }}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col xs=8 sm="9" md=10>
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                  :disabled="isLoading"
                ></v-text-field>
              </v-col>
              <v-col xs=4 sm="3" md=2 align="right" v-if="canCreate">
                <v-btn  small color="primary" elevation="2" @click="newProceso()">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="downloadFile(item)"
              >
                {{ downloadIcon }}
              </v-icon>
            </template>
            <span>Generar nuevamente</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openDeleteProceso(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar proceso</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div v-if="modalViewArchivo">
      <v-dialog
        v-model="modalViewArchivo"
        :max-width="'60%'"
        @keydown.esc="modalViewArchivo = false"
      >
        <DetalleArchivoAfip
          :archivoId="archivoSelected.actasFiscalizacionGeneracionArchivoId"
          @close="modalViewArchivo = false"
          @closeAndReload="closeModalAndReload"
        />
      </v-dialog>
    </div>
    <div v-if="openModalDownload">
      <DatosArchivoAfip
        :modalOpen="openModalDownload"
        v-on:update:modalOpen="openModalDownload = $event"
        :actas="actasGeneracionArchivo" />
    </div>
    <DeleteDialog
      :textDelete="textDelete"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteProceso()"
    />
    </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import helper from "../../../../utils/helpers";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DetalleArchivoAfip from "@/components/modules/cuotas/aportes/DetalleArchivoAfip";
import DatosArchivoAfip from "@/components/modules/cuotas/aportes/DatosArchivoAfip.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "GestionProcesoInformeAfip",
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    DetalleArchivoAfip,
    DatosArchivoAfip,
    Ayuda
  },
  data: vm => ({
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
        .toISOString()
        .substr(0, 10)
    ),
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    vencimientoHastaSelected: vm.formatDate(
      new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 60)
        .toISOString()
        .substring(0, 10)
    ),
    title: enums.titles.GESTION_PROCESOS_AFIP,
    searchIcon: enums.icons.SEARCH,
    text: "Procesos",
    rules: rules,
    textDelete: null,
     optionCode: enums.webSiteOptions.GESTION_PROCESOS_INFORME_AFIP,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    isLoading: false,
    itemsPerPageOptions: [10, 20, 30, 40, 50],
    itemPerPage: 10,
    openDeleteDialog: false,
    editedIndex: -1,
    showFilters: true,
    isFormValid: true,
    nroProceso: null,
    modalViewArchivo: false,
    archivosFiscalizacion: [],
    singleSelect: false,
    periodo: null,
    formatoToDelete: {},
    search: "",
    openModalDownload: false,
    archivoId:null,
    actaToDelete: {},
    sortByProceso: "actasFiscalizacionGeneracionArchivoId",
    archivoSelected: {},
    actasGeneracionArchivo: [],
    periodoArchivo: null,
    secuenciaArchivo: null,
    codigoINOS: null,
    sortDesc: true,
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    filtersApplied: [],
    calendarIcon: enums.icons.CALENDAR,
    editIcon: enums.icons.SEE,
    deleteIcon: enums.icons.DELETE,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    downloadIcon: enums.icons.DOWNLOAD,
    procesosHeader: [
      {
        text: "Archivo n°",
        align: "start",
        value: "actasFiscalizacionGeneracionArchivoId"
      },
      { text: "Usuario alta", value: "usuAlta" },
      { text: "Fecha alta", value: "fecAlta" },
      { text: "Período", value: "periodo" },
      { text: "Entidad facturante", value: "actasFiscalizacionGeneracionArchivoDetalle[0].entidadFacturante" },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canDelete: false,
    canCreate: false,
  }),
  computed: {
    ...mapGetters({}),
    sortBy: {
      get() {
        return this.sortByProceso;
      },
      set(newVal) {
        return newVal;
      }
    }
  },

  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    this.applyFilters();
    this.$bus.$on("closeModalDatos", () => {
      this.openModalDownload = false;
      this.actasGeneracionArchivo = [];
    });

    this.$bus.$on("confirmFileAfip", data => {
      this.confirmDownload(data);
    });
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchArchivosFiscalizacionAfip: "aportes/fetchArchivosFiscalizacionAfip",
      deleteActaById: "aportes/deleteActaById",
      deleteProcesoAfip: "aportes/deleteProcesoAfip",
      generarArchivoInformeAfip: "aportes/generarArchivoInformeAfip",
      getArchivoFiscalizacionDetalleById:
        "aportes/getArchivoFiscalizacionDetalleById",
      getEmpresaByActaId: "aportes/getEmpresaByActaId"
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_INFORME_AFIP:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_INFORME_AFIP:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      this.isLoading = true;

      this.filtersApplied = this.formatFilters(this.$refs["filters-form"]);
      this.customizeFiltersApplied();
      const params = {
        fechaDesde: `${this.parseDate(this.fechaDesdeSelected)} 00:00:00`,
        fechaHasta: `${this.parseDate(this.fechaHastaSelected)} 23:59:00`,
        periodo: this.periodo
      };

      try {
        const response = await this.fetchArchivosFiscalizacionAfip({ params });
        this.archivosFiscalizacion = response;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    customizeFiltersApplied() {
     // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = this.filtersApplied.filter(x => {
        return (
          x.label !== "Período informado"
        );
      });
      this.filtersApplied.push(
        {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        },
        {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        }
      );

      if (this.periodo) {
        this.filtersApplied.push({
          key: "periodo",
          label: "Período informado",
          model: this.periodo
        });
      }
    },
    cleanFiltersSelected() {
      this.filtersApplied = [];
    },
    editProceso(item) {
      this.modalViewArchivo = true;
      this.archivoSelected = item;
    },
    closeModalAndReload() {
      this.modalViewArchivo = false;
      this.applyFilters();
    },
    openDeleteProceso(item) {
      this.formatoToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteProceso() {
      const res = await this.deleteProcesoAfip(
        this.formatoToDelete.actasFiscalizacionGeneracionArchivoId
      );
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
      this.formatoToDelete = {};
      this.textDelete = null;
    },
    newProceso() {
      this.$router.push({ name: "ProcesoInformeAFIP" });
    },
    resetForm() {
      this.filtersApplied = [];
      this.$refs["periodoD"].reset();
    },
    formatFilters(ref) {
      if (typeof ref === "undefined") return;
      return ref.$children
        .map(f => {
          if (f.value)
            return { key: f.id, label: f.label, model: f.selectedItems };
          return null;
        })
        .filter(f => f);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async downloadFile(item) {
      this.openModalDownload = true;
      const response = await this.getArchivoFiscalizacionDetalleById(
        item.actasFiscalizacionGeneracionArchivoId
      );
      this.archivoId = item.actasFiscalizacionGeneracionArchivoId;
      response.forEach(detalle => {
        this.actasGeneracionArchivo.push(detalle.acta);
      });
    },
    async confirmDownload(data) {
      let actasToSend = [];
      this.actasGeneracionArchivo.forEach(acta => {
        actasToSend.push({ 
          actasFiscalizacionId: acta.actasFiscalizacionId,
          empresa: acta.empresa,
          entidadFacturante: acta.entidadFacturante,
          estado: acta.estado,
          estadoId: acta.estadoId,
          estadosIds: acta.estadosIds,
          fecha: this.parseDateToIso(acta.fecha),
          importe: acta.importe
        });
      });
      const params = {
        generacionArchivoId: this.archivoId,
        periodoArchivo: data.periodo,
        secuenciaArchivo: data.secuencia,
        codigoINOS: data.codigo,
        actasFiscalizacionSeleccionadas: actasToSend
      };
      var cuit = "";
      for (
        var i = 0;
        i < params.actasFiscalizacionSeleccionadas.length;
        i++
      ) {
        var acta = params.actasFiscalizacionSeleccionadas[i];
        var empresa = await this.getEmpresaByActaId(
          acta.actasFiscalizacionId
        );
        cuit = empresa.entidadFiscalizadoraCuit;

        if (cuit != "" && cuit != null) {
          i = params.actasFiscalizacionSeleccionadas.length;
        }
      }
      if (params.actasFiscalizacionSeleccionadas.length > 0) {
        const response = await this.generarArchivoInformeAfip({ params });
        var name =
          "F8134." +
          cuit + //Cuit de cada empresa?
          //params.CuitArchivo'' +
          "." +
          params.periodoArchivo +
          "." +
          params.secuenciaArchivo;
        if (response.status === 200 && (cuit == "" || cuit == null)) {
          this.setAlert({
            type: "info",
            message:
              "Archivo creado con éxito pero sin CUIT informante válido."
          });
        } else if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Archivo creado con éxito"
          });
        }
        helper.downloadFile(response.data, name, "txt");
        this.openModalDownload = false;
        this.actasGeneracionArchivo = [];
        this.applyFilters();
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }

  }
};
</script>

<style lang="scss" scoped>
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
