var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-5 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('PageHeader',{staticClass:"pt-5 ",attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"1","align-self":"center","align":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFilters),expression:"!showFilters"}]},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","align":"left"}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"outlined":"","dense":"","hint":"Formato DD/MM/AAAA","rules":[
                      (!!_vm.fechaDesdeSelected &&
                        !!_vm.fechaHastaSelected &&
                        new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                          new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))) ||
                        'Formato incorrecto'
                    ]},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","dense":"","rules":[
                      (!!_vm.fechaDesdeSelected &&
                        !!_vm.fechaHastaSelected &&
                        new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                          new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))) ||
                        'Formato incorrecto'
                    ]},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{ref:"periodoD",attrs:{"label":"Período informado","outlined":"","dense":"","clearable":"","autocomplete":"not","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.periodoYyyyMm,"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"actasTable","headers":_vm.procesosHeader,"header-props":{ sortIcon: null },"items":_vm.archivosFiscalizacion,"loading":_vm.isLoading,"search":_vm.search,"single-select":_vm.singleSelect,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions
      },"items-per-page":_vm.itemPerPage},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.importe)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"8","sm":"9","md":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":"","disabled":_vm.isLoading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"xs":"4","sm":"3","md":"2","align":"right"}},[_c('v-btn',{attrs:{"small":"","color":"primary","elevation":"2"},on:{"click":function($event){return _vm.newProceso()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.downloadFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.downloadIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Generar nuevamente")])]),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openDeleteProceso(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar proceso")])]):_vm._e()]}}],null,true)})],1),(_vm.modalViewArchivo)?_c('div',[_c('v-dialog',{attrs:{"max-width":'60%'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.modalViewArchivo = false}},model:{value:(_vm.modalViewArchivo),callback:function ($$v) {_vm.modalViewArchivo=$$v},expression:"modalViewArchivo"}},[_c('DetalleArchivoAfip',{attrs:{"archivoId":_vm.archivoSelected.actasFiscalizacionGeneracionArchivoId},on:{"close":function($event){_vm.modalViewArchivo = false},"closeAndReload":_vm.closeModalAndReload}})],1)],1):_vm._e(),(_vm.openModalDownload)?_c('div',[_c('DatosArchivoAfip',{attrs:{"modalOpen":_vm.openModalDownload,"actas":_vm.actasGeneracionArchivo},on:{"update:modalOpen":function($event){_vm.openModalDownload = $event}}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"textDelete":_vm.textDelete,"openDelete":_vm.openDeleteDialog},on:{"update:openDelete":function($event){_vm.openDeleteDialog=$event},"update:open-delete":function($event){_vm.openDeleteDialog=$event},"onDeleteItem":function($event){return _vm.deleteProceso()}}})],1),_c('v-col',{staticClass:"to-right pt-7 px-0 ",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }